import { AnyAction } from "redux";
import { pipelineFilters, searchTransactionsActions as actionTypes } from "../constants";
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { SearchTransactionState } from "../types/state/SearchTransactionState";

const initialState: SearchTransactionState = {
    searchTerm: "",
    searchTermItems: [],
    dealItems: [],
    classesLookup: [],
    managerItems: [],
    markerPosition: -1,
    lookup: [],
    lookupCache: [],
    searchTermCache: "",
    isSearching: false,
    dealsOffset: 0,
    classesOffset: 0,
    hasMoreDeals: false,
    hasMoreClasses: false,
};

export const createSearchTransactionsNamedData = (pipelineType: PipelineType) => (
    state: SearchTransactionState = initialState,
    action: AnyAction
) => {
    if (action.pipelineType !== pipelineType) {
        return state;
    }

    switch (action.type) {
        case actionTypes.SEARCH_TERM_CHANGE:
            return {
                ...state,
                searchTerm: action.searchTerm,
            };
        case actionTypes.SEARCH_LOOKUP_CACHE:
            return {
                ...state,
                lookupCache: [
                    ...action.isLoadMore ? state.lookupCache : [],
                    ...action.lookupData,
                ],
                lookup: [
                    ...action.isLoadMore ? state.lookup : [],
                    ...action.lookupData,
                ],
                classesLookup: [
                    ...action.isLoadMore ? state.classesLookup : [],
                    ...action.classesLookup,
                ],
                searchTermCache: action.searchTerm,
                hasMoreDeals: action.lookupData.length === pipelineFilters.searchItemsPerLoad,
                hasMoreClasses: action.classesLookup.length === pipelineFilters.searchItemsPerLoad,
                dealsOffset: state.dealsOffset + action.lookupData.length,
                classesOffset: state.classesOffset + action.classesLookup.length,
            };
        case actionTypes.SEARCH_ADD_DEALS:
            return {
                ...state,
                hasMoreDeals: action.lookupData.length === pipelineFilters.searchItemsPerLoad,
                lookup: [...(action.isLoadMore ? state.lookup : []), ...action.lookupData],
                dealsOffset: state.dealsOffset + action.lookupData.length,
                searchTermCache: action.searchTerm,
            };
        case actionTypes.SEARCH_ADD_CLASSES:
            return {
                ...state,
                hasMoreClasses: action.lookupData.length === pipelineFilters.searchItemsPerLoad,
                classesLookup: [...(action.isLoadMore ? state.classesLookup : []), ...action.lookupData],
                classesOffset: state.classesOffset + action.lookupData.length,
                searchTermCache: action.searchTerm,
            };
        case actionTypes.SEARCH_RESET_LOOKUP_CACHE:
            return {
                ...state,
                lookupCache: [],
                lookup: [],
                searchTermCache: "",
                hasMoreDeals: false,
                hasMoreClasses: false,
                dealsOffset: 0,
                classesOffset: 0,
            };
        case actionTypes.SEARCH_LOOKUP:
            return {
                ...state,
                lookup: action.lookupData,
                classesLookup: action.classesLookup,
            };
        case actionTypes.SEARCH_ITEM_ADD:
            return {
                ...state,
                searchTermItems: [
                    ...state.searchTermItems,
                    action.item,
                ],
                markerPosition: -1,
            };
        case actionTypes.SEARCH_ITEM_REMOVE:
            return {
                ...state,
                searchTermItems: state.searchTermItems.filter(
                    (s, i) => i !== action.index
                ),
                markerPosition: getMarkerPositionAfterItemRemove(
                    state.markerPosition,
                    action.index
                ),
            };
        case actionTypes.SEARCH_REMOVE_CURRENT_ITEM:
            if (
                state.markerPosition > -1 &&
                state.markerPosition <
                    state.searchTermItems.length
            ) {
                return {
                    ...state,
                    searchTermItems: state.searchTermItems.filter(
                        (s, i) => i !== state.markerPosition
                    ),
                };
            }

            return state;
        case actionTypes.SEARCH_RESET:
            return initialState;
        case actionTypes.SEARCH_ITEMS_REQUESTING:
            return { ...state, isSearching: action.isSearching };
        case actionTypes.SEARCH_MOVE_BACK:
        case actionTypes.SEARCH_MOVE_FORWARD:
            return searchItemNavigationReducer(state, action);
        default:
            return state;
    }
};

function searchItemNavigationReducer(
    state: SearchTransactionState = initialState,
    action: AnyAction
) {
    let markerPosition = state.markerPosition;

    if (action.type === actionTypes.SEARCH_MOVE_BACK) {
        if (markerPosition > 0) {
            return { ...state, markerPosition: --markerPosition };
        }
        if (markerPosition === -1) {
            return {
                ...state,
                markerPosition: state.searchTermItems.length - 1,
            };
        }
    }
    if (action.type === actionTypes.SEARCH_MOVE_FORWARD) {
        if (
            markerPosition >= 0 &&
            markerPosition < state.searchTermItems.length - 1
        ) {
            return { ...state, markerPosition: ++markerPosition };
        }
        return { ...state, markerPosition: -1 };
    }
    return state;
}

function getMarkerPositionAfterItemRemove(
    markerPosition: number,
    removedIndex: number
) {
    return markerPosition > removedIndex ? markerPosition - 1 : markerPosition;
}
